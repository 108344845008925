@import url('https://fonts.googleapis.com/css?family=Archivo+Narrow|Do+Hyeon|IBM+Plex+Sans:400,600,700i|Righteous|Ubuntu&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Ubuntu', sans-serif
}

body {
    color: #091834;
}