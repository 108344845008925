@import url(https://fonts.googleapis.com/css?family=Archivo+Narrow|Do+Hyeon|IBM+Plex+Sans:400,600,700i|Righteous|Ubuntu&display=swap);
.header-container  {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #12075d;
    background-image: url('/techBack.jpg');/*#1258DC;*/
    background-repeat: no-repeat;
    color: white;
    min-height: 120px;
    align-items: center;
    justify-content: center;
}

.header-container img {
    bottom: 75px;
}

.header-container .header-name {
    font-family: 'Righteous', cursive;
    font-size: 55px;
    letter-spacing: 10px;
    opacity: 0;
    -webkit-animation: show 1.2s forwards;
            animation: show 1.2s forwards;
    text-align: center;
}

.flag {
    position: absolute;
    right: 16px;
    bottom: 3px;
    cursor: pointer;
    z-index: 1;
}

@media only screen and (min-width: 768px) {
    .header-container  {
        justify-content: space-between;
    }

    .header-container img {
        bottom: 0;
    }
    
    .header-container .header-name {
        font-size: 80px;
    }
}

@media only screen and (min-width: 400px) {

    .header-container img {
        bottom: 0;
    }
    
}
.contact-container {
    display: flex;
    flex-direction: row;
    margin: 5px 20px;
    align-items: center;
    color: white;
    text-decoration: none;
}

.contact-container svg {
    margin-right: 5px;
}

.contact-container span {
    display: none;
}

@media only screen and (min-width: 993px) {
    .contact-container span{
        display: inline;
    }
}
.contacts-container{
    display: flex;
    flex-direction: row;
    opacity: 0;
    -webkit-animation: show 1.2s forwards;
            animation: show 1.2s forwards;
}

#footer{
    display: flex;
    flex-direction: row;
    opacity: 1;
    text-align: center;
    justify-content: space-around;
    font-size: 25px;
}

@media only screen and (min-width: 993px) {
    #footer{
        flex-direction: column;
    }
}
.course-item-container {
    position: relative;
    flex: 1 0 340px;
    display: grid;
    left: 0;
    top: 0;
    border-radius: 15px;
    overflow: hidden;
    align-items: flex-start;
    background: #DEE9FC;
    border: 1px solid #6395F2;
    text-decoration: none;
    color: #1258DC;
    margin-right: 16px;
}

.course-item-container:hover {
    cursor: pointer;
}

.course-item-container img {
    width: 100%;
}

.course-item-container .course-item-description {
    padding: 25px;
    color: #0A337F;
    font-size:14px;
    line-height: 24px;
    text-align: justify;
}

@media only screen and (min-width: 769px) {
    .course-item-container {
        flex: 1 1;
        grid-template-columns: 1fr;
        transition: box-shadow 0.2s, left 0.5s, top 0.5s, transform 0.5s;
        margin-right: 0;
    }
    
    .course-item-container:hover {
        box-shadow: 15px 15px 12px gray;
        left: -5px;
        top: -5px;
        transform: scale(1.1);
    }
    
}
.portifolio-item-mobile {
    position: relative;
    left: 0;
    top: 0;
    flex: 1 0 420px;
    display: grid;
    background: #0A337F;
    color: #DEE9FC;
    grid-template-columns: minmax(180px,200px) 1fr;
    margin: 0 15px;
    overflow: hidden;
    border-radius: 16px;
    font-size: 16px;
    box-shadow: 12px 12px 10px gray;
    min-width: 420px;
}

.portifolio-item-mobile > .content {
    display: flex;
    flex-direction: column;
}

.portifolio-item-mobile .gif {
    width: 100%;
}

.portifolio-item-mobile .description {
    flex:1 1;
    text-align: justify;
    padding: 25px;
    margin: 0;
    line-height: 24px;
    width: 100%;
}

.portifolio-item-mobile .link-container {
    display: flex;
    justify-content: space-around;
}

.portifolio-item-mobile .link-container a {
    display: inline-block;
    text-decoration:none;
    color: #DEE9FC;
    padding: 10px;
}

.portifolio-item-web {
    display: flex;
    flex: 1 0 360px;
    background: #0A337F;
    color: #DEE9FC;
    flex-direction: column;
    margin: 0 15px;
    overflow: hidden;
    border-radius: 16px;
    font-size: 16px;
    box-shadow: 12px 12px 10px gray;
    max-width: 250px;

}

.portifolio-item-web .gif {
    max-width: 250px;
    margin: 0 auto;
}

.portifolio-item-web > .content {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.portifolio-item-web .description {
    flex: 1 1;
    text-align: justify;
    margin: 0;
    padding: 25px;
    line-height: 24px;
}

.portifolio-item-web .link-container {
    display: flex;
    justify-content: space-around;
}

.portifolio-item-web .link-container a {
    display: inline-block;
    text-decoration:none;
    color: #DEE9FC;
    padding: 10px;
}
@media only screen and (min-width: 796px) {
    .portifolio-item-mobile {
        position: relative;
        left: 0;
        top: 0;
        display: grid;
        background: #0A337F;
        color: #DEE9FC;
        grid-template-columns: minmax(180px,200px) 1fr;
        margin: 0 15px;
        overflow: hidden;
        border-radius: 16px;
        font-size: 16px;
        box-shadow: 12px 12px 10px gray;
        min-width: 360px;
    }

    .portifolio-item-web {
        display: flex;
        background: #0A337F;
        color: #DEE9FC;
        flex-direction: column;
        margin: 0 15px;
        overflow: hidden;
        border-radius: 16px;
        font-size: 16px;
        box-shadow: 12px 12px 10px gray;
        max-width: 250px;
    }
}
.grid-colllection-container {
    width: 100%;
    margin: 0;
    padding: 30px 20px 100px;
}

.grid-colllection-container .grid-collection {
    display: flex;
    overflow: scroll;
    margin: 0 auto;
}

.grid-colllection-container .section-title {
    margin-bottom: 50px;
} 

#profile-collection {
    display: flex;
    overflow-x: scroll;
    margin: 0 auto;
}


@media only screen and (min-width: 769px) {
    .grid-colllection-container {
        padding: 100px;
    }
    
    .grid-colllection-container .grid-collection {
        display: grid;
        grid-gap: 50px;
        overflow: unset;
        grid-template-columns: repeat(auto-fit, minmax(200px, auto));
    }
}
footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #091834;
    color: #6395F2;
    min-height: 300px;
}

footer a {
    color: white;
    margin-top: 8px;
    text-decoration: none;
}

footer a:visited {
    color: white;
}
.home-container .job-name {
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-family: 'Do Hyeon', sans-serif;
    font-style: italic;
    letter-spacing: 45px;
    color: #0A337F;
    font-weight: normal;
    background: #DEE9FC;
    padding: 20px 0;
    white-space: nowrap;
    -webkit-animation: jobtitle 0.8s forwards;
            animation: jobtitle 0.8s forwards;
}

.home-container .profile-container {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: space-around;
    align-items: center;
    padding: 70px 10px;
    box-shadow: 0 2px 3px #DEE9FC;
    background-image: linear-gradient(75deg, #1258dc,  #0a337f) /*#0a337f;*/
}

.home-container .profile-container .profile-photo {
    position: relative;
    width: 200px;
    left: -20px;
    border-radius: 8px;
    box-shadow: 5px 5px 8px #6395F2;
    opacity: 0;
    -webkit-animation: show 0.8s forwards 0.5s ;
            animation: show 0.8s forwards 0.5s ;
    margin: 0 auto 25px;
}

.home-container .profile-container .profile-description-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: #DEE9FC;
    left: 20px;
    opacity: 0;
    -webkit-animation: show 0.8s forwards 0.5s;
            animation: show 0.8s forwards 0.5s;
}

.hom#0a337f,e-container .section-title {
    padding-bottom: 30px;
    color: #6395F2;
    font-family: 'Righteous', cursive;
    font-weight: normal;
    font-size: 30px;
    letter-spacing: 8px;
    text-align: center;
}

.home-container .profile-container .profile-description-container .profile-description {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 3px;
    padding: 8px;
    text-align: justify;
}

.home-container .profile-container .profile-description-container .profile-description strong{
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 1.1em;
    font-weight: bold;
    color: white;
}

.home-container .profile-container .description-brands {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-self: center;
    padding: 10px 10px;
}

.home-container .profile-container .description-brands svg{
    padding:10px 0;
    box-sizing: content-box;
    color: #1258DC;
}

@-webkit-keyframes show {
    to {left: 0; opacity: 1;}
}

@keyframes show {
    to {left: 0; opacity: 1;}
}

@-webkit-keyframes jobtitle {
    to {letter-spacing: 1px; opacity: 1;}
}

@keyframes jobtitle {
    to {letter-spacing: 1px; opacity: 1;}
}


@media only screen and (min-width: 600px) and (max-width: 767px) {
    .home-container .job-name {
        font-size: 25px;
    }
    
    @-webkit-keyframes jobtitle {
        to {letter-spacing: 5px; opacity: 1;}
    }
    
    @keyframes jobtitle {
        to {letter-spacing: 5px; opacity: 1;}
    }
    
}

@media only screen and (min-width: 768px) {
    .home-container .job-name {
        font-size: 38px;
    }

    @-webkit-keyframes jobtitle {
        to {letter-spacing: 5px; opacity: 1;}
    }

    @keyframes jobtitle {
        to {letter-spacing: 5px; opacity: 1;}
    }

    .home-container .profile-container {
        background-image: linear-gradient(to right, #1258dc,  #0a337f) /*#0a337f;*/
    }
}

@media only screen and (min-width: 992px) {
    .home-container .profile-container {
        grid-template-columns: auto 1fr auto;
        justify-content: space-between;
        align-items: flex-start;
        padding: 70px 0 70px 100px;
    }

    .home-container .profile-container .profile-description-container .profile-description {
        font-size: 18px;
        line-height: 24px;
        padding-left:50px;
        letter-spacing: 3px;
        text-align: justify;
    }
    

    .home-container .profile-container .profile-photo {
        position: relative;
        width: 200px;
        left: -20px;
        border-radius: 8px;
        box-shadow: 5px 5px 8px #6395F2;
        opacity: 0;
        -webkit-animation: show 0.8s forwards 0.5s ;
                animation: show 0.8s forwards 0.5s ;
        margin: 0;
    }

    .home-container .profile-container .description-brands {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-self: center;
        padding: 0 10px 0 80px;
    }
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Ubuntu', sans-serif
}

body {
    color: #091834;
}
