.home-container .job-name {
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-family: 'Do Hyeon', sans-serif;
    font-style: italic;
    letter-spacing: 45px;
    color: #0A337F;
    font-weight: normal;
    background: #DEE9FC;
    padding: 20px 0;
    white-space: nowrap;
    animation: jobtitle 0.8s forwards;
}

.home-container .profile-container {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: space-around;
    align-items: center;
    padding: 70px 10px;
    box-shadow: 0 2px 3px #DEE9FC;
    background-image: linear-gradient(75deg, #1258dc,  #0a337f) /*#0a337f;*/
}

.home-container .profile-container .profile-photo {
    position: relative;
    width: 200px;
    left: -20px;
    border-radius: 8px;
    box-shadow: 5px 5px 8px #6395F2;
    opacity: 0;
    animation: show 0.8s forwards 0.5s ;
    margin: 0 auto 25px;
}

.home-container .profile-container .profile-description-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: #DEE9FC;
    left: 20px;
    opacity: 0;
    animation: show 0.8s forwards 0.5s;
}

.hom#0a337f,e-container .section-title {
    padding-bottom: 30px;
    color: #6395F2;
    font-family: 'Righteous', cursive;
    font-weight: normal;
    font-size: 30px;
    letter-spacing: 8px;
    text-align: center;
}

.home-container .profile-container .profile-description-container .profile-description {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 3px;
    padding: 8px;
    text-align: justify;
}

.home-container .profile-container .profile-description-container .profile-description strong{
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 1.1em;
    font-weight: bold;
    color: white;
}

.home-container .profile-container .description-brands {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-self: center;
    padding: 10px 10px;
}

.home-container .profile-container .description-brands svg{
    padding:10px 0;
    box-sizing: content-box;
    color: #1258DC;
}

@keyframes show {
    to {left: 0; opacity: 1;}
}

@keyframes jobtitle {
    to {letter-spacing: 1px; opacity: 1;}
}


@media only screen and (min-width: 600px) and (max-width: 767px) {
    .home-container .job-name {
        font-size: 25px;
    }
    
    @keyframes jobtitle {
        to {letter-spacing: 5px; opacity: 1;}
    }
    
}

@media only screen and (min-width: 768px) {
    .home-container .job-name {
        font-size: 38px;
    }

    @keyframes jobtitle {
        to {letter-spacing: 5px; opacity: 1;}
    }

    .home-container .profile-container {
        background-image: linear-gradient(to right, #1258dc,  #0a337f) /*#0a337f;*/
    }
}

@media only screen and (min-width: 992px) {
    .home-container .profile-container {
        grid-template-columns: auto 1fr auto;
        justify-content: space-between;
        align-items: flex-start;
        padding: 70px 0 70px 100px;
    }

    .home-container .profile-container .profile-description-container .profile-description {
        font-size: 18px;
        line-height: 24px;
        padding-left:50px;
        letter-spacing: 3px;
        text-align: justify;
    }
    

    .home-container .profile-container .profile-photo {
        position: relative;
        width: 200px;
        left: -20px;
        border-radius: 8px;
        box-shadow: 5px 5px 8px #6395F2;
        opacity: 0;
        animation: show 0.8s forwards 0.5s ;
        margin: 0;
    }

    .home-container .profile-container .description-brands {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-self: center;
        padding: 0 10px 0 80px;
    }
}